<template>
  <div>
    <!-- <Navigation :show-back="true" />
    <v-main>
      <v-container> -->
    <div v-if="userType === 'public'">
      <v-row justify="center">
        <v-col cols="12" md="10" lg="8">
          <v-banner>
            <v-icon slot="icon" color="warning" size="36">
              mdi-account-alert-outline
            </v-icon>
            Sign in to view {{ business.name }} schedules.
            <template v-slot:actions>
              <v-btn color="primary" text @click="showSignIn = true">
                Sign In
              </v-btn>
            </template>
            <sign-in-dialog
              v-if="showSignIn"
              @close="showSignIn = false"
            ></sign-in-dialog>
          </v-banner>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="userType === 'guest'">
      <v-row justify="center">
        <v-col cols="12" md="10" lg="8">
          <v-banner>
            <v-icon slot="icon" color="warning" size="36">
              mdi-account-alert-outline
            </v-icon>
            <div class="d-flex clickable" @click="expand = !expand">
              <span class="font-weight-bold py-2">
                Are you one of the {{ business.custtype.toLowerCase() }} of
                {{ business.name }}?
              </span>
              <v-spacer></v-spacer>
              <v-icon>{{
                expand ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </div>
            <v-expand-transition>
              <div v-show="expand">
                <p>
                  If yes, your FeePlus account has not been linked. Check with
                  {{ business.name }} and make sure your registered email is the
                  same with their customer record to join an event.
                </p>
              </div>
            </v-expand-transition>
          </v-banner>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-stepper v-model="e6" vertical>
        <v-list-item>
          <v-list-item-avatar color="indigo" size="45">
            <img
              v-if="requestor.picture_link"
              :src="requestor.picture_link"
            />
            <!-- <span v-else-if="user.initial" class="white--text headline">{{
                user.initial
              }}</span> -->
            <v-icon v-else class="white--text">mdi-account-circle</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="headline">{{
              requestor.fullname
            }}</v-list-item-title>
            <!-- <v-list-item-subtitle>Join Event</v-list-item-subtitle> -->
          </v-list-item-content>
        </v-list-item>
        <v-stepper-step :complete="e6 > 1" step="1">
          Select an appointment
          <small v-if="selectedData.appointment" class="d-inline">{{
            selectedData.appointment.appointment_title
          }}</small>
          <!-- <small>Summarize if needed</small> -->
        </v-stepper-step>
        <v-stepper-content step="1">
          <v-card color="" class="mb-12">
            <v-list>
              <v-list-item-group v-model="selected.appointment" color="primary">
                <template v-for="(appointment, index) in appointments">
                  <v-list-item :key="appointment.appointment_id">
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title class="d-sm-flex">
                          <span class="font-weight-bold my-title">{{
                            appointment.appointment_title
                          }}</span>
                          <v-spacer></v-spacer>
                          <span>Duration: {{ appointment.duration }} mins</span>
                        </v-list-item-title>
                        <div class="d-flex flex-wrap">
                          <v-chip
                            v-if="appointment.passes"
                            color="green"
                            text-color="white"
                            ><v-icon left small>mdi-ticket-confirmation</v-icon>
                            Require Pass
                            <v-avatar right class="green darken-4">
                              {{ appointment.passes }}
                            </v-avatar>
                          </v-chip>
                        </div>
                      </v-list-item-content>
                      <v-list-item-action>
                        <div>
                          <v-icon v-if="!active" color="grey lighten-1">
                            mdi-checkbox-blank-outline
                          </v-icon>

                          <v-icon v-else color="green">
                            mdi-checkbox-marked
                          </v-icon>
                        </div>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                  <v-divider :key="index"></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card>
          <v-btn color="primary" @click="validateStepper(1)">Continue</v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 2" step="2"
          >Select passes
          <small v-if="selectedData.pass" class="d-inline">{{
            selectedData.pass.service_name
          }}</small>
        </v-stepper-step>
        <v-stepper-content step="2">
          <v-card color="" class="mb-12">
            <v-alert
              v-if="!selectedData.appointment.need_passes"
              type="success"
            >
              No passes is required for this appointment. Click continue.
            </v-alert>
            <v-alert v-if="passesList && !passesList.length" type="error">
              Selected appointment require pass. Unfortunately, you do not have
              any pass.
            </v-alert>
            <v-list v-else>
              <v-list-item-group v-model="selected.pass" color="primary">
                <template v-for="(pass, index) in passesList">
                  <v-list-item
                    :key="pass.service_record_id"
                    :disabled="!pass.allowed"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title class="d-sm-flex">
                          <span class="font-weight-bold my-title">{{
                            pass.service_name
                          }}</span>
                          <v-spacer></v-spacer>
                          <div>
                            Balance {{ pass.passes_counter }}
                            <v-icon>mdi-ticket</v-icon>
                          </div>
                        </v-list-item-title>
                        <div class="d-flex flex-wrap">
                          <div class="flex-column">
                            <div v-if="pass.invoice_no">
                              (Invoice No: {{ pass.invoice_no }})
                            </div>
                            <div>
                              Expired: {{ toDate(pass.service_dateto) }}
                            </div>
                          </div>
                          <v-chip
                            v-if="!pass.allowed"
                            color="red"
                            dark
                            class="ml-auto"
                          >
                            Not Applicable
                            <v-icon right>mdi-cancel</v-icon>
                          </v-chip>
                        </div>
                      </v-list-item-content>
                      <v-list-item-action>
                        <div v-if="pass.allowed">
                          <v-icon v-if="!active" color="grey lighten-1">
                            mdi-checkbox-blank-outline
                          </v-icon>

                          <v-icon v-else color="green">
                            mdi-checkbox-marked
                          </v-icon>
                        </div>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                  <v-divider :key="index"></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card>
          <v-btn color="primary" @click="validateStepper(2)">Continue</v-btn>
          <v-btn text @click="e6 = 1">Previous</v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 3" step="3"
          >Select trainer
          <small v-if="selectedData.stakeholder" class="d-inline">{{
            selectedData.stakeholder.firstname
          }}</small>
        </v-stepper-step>
        <v-stepper-content step="3">
          <v-card color="" class="mb-12">
            <v-alert
              v-if="!selectedData.appointment.need_stakeholder"
              type="success"
            >
              No trainer is required for this appointment. Click continue.
            </v-alert>
            <v-alert
              v-else-if="
                selectedData.appointment.private_stakeholder &&
                  !selectedData.pass.stakeholder_id
              "
              type="error"
            >
              No private trainer found in your appointment pass record. Please
              contact {{ business.name }} to rectify this problem.
            </v-alert>
            <v-alert
              v-else-if="
                !(Array.isArray(stakeholdersList) && stakeholdersList.length)
              "
              type="error"
            >
              Trainer is require for this appointment but none is found. Please
              contact {{ business.name }} to rectify this problem.
            </v-alert>
            <v-list v-else>
              <v-list-item-group v-model="selected.stakeholder" color="primary">
                <template v-for="(stakeholder, index) in stakeholdersList">
                  <v-list-item :key="stakeholder.stakeholder_id">
                    <template v-slot:default="{ active }">
                      <v-list-item-avatar>
                        <img
                          v-if="stakeholder.picture_link"
                          :src="stakeholder.picture_link"
                        />
                        <v-icon v-else class="white--text"
                          >mdi-account-circle</v-icon
                        >
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span class="font-weight-bold my-title">{{
                            stakeholder.firstname + " " + stakeholder.lastname
                          }}</span>
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <div>
                          <v-icon v-if="!active" color="grey lighten-1">
                            mdi-checkbox-blank-outline
                          </v-icon>

                          <v-icon v-else color="green">
                            mdi-checkbox-marked
                          </v-icon>
                        </div>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                  <v-divider :key="index"></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card>
          <v-btn color="primary" @click="validateStepper(3)">Continue</v-btn>
          <v-btn text @click="e6 = 2">Previous</v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 4" step="4"
          >Select date & time
          <small v-if="selectedData.datetime.date" class="d-inline">{{
            selectedData.datetime.date +
              " " +
              selectedData.datetime.timefrom +
              " " +
              selectedData.datetime.timeto
          }}</small>
        </v-stepper-step>
        <v-stepper-content step="4">
          <v-card color="" class="mb-12 d-flex flex-wrap align-start">
            <div style="max-width:290px">
              <v-date-picker
                v-model="datePicker.value"
                :first-day-of-week="datePicker.firstDayOfWeek"
                :min="datePicker.min"
                :max="datePicker.max"
                @change="datePickerChanged"
                full-width
              >
              </v-date-picker>
            </div>
            <div
              class="d-flex flex-grow-1 justify-space-between px-sm-4 flex-wrap"
              style="max-width:400px; position: inherit;"
            >
              <div class="d-flex flex-column">
                <div class="d-flex">Morning</div>
                <div class="d-flex flex-column">
                  <v-btn
                    v-for="button in timePicker.timeslots.morning.buttons"
                    :key="button.value"
                    color="primary"
                    :outlined="!button.active"
                    :disabled="button.disabled"
                    class="ma-1"
                    @click="timeClicked(button.value)"
                  >
                    {{ button.label }}
                  </v-btn>
                </div>
              </div>
              <div class="d-flex flex-column">
                <span class="">Afternoon</span>
                <div class="d-flex flex-column">
                  <v-btn
                    v-for="button in timePicker.timeslots.afternoon.buttons"
                    :key="button.value"
                    color="primary"
                    :outlined="!button.active"
                    :disabled="button.disabled"
                    class="ma-1"
                    @click="timeClicked(button.value)"
                  >
                    {{ button.label }}
                  </v-btn>
                </div>
              </div>
              <div class="d-flex flex-column">
                <span class="d-flex">Evening</span>
                <div class="d-flex flex-column">
                  <v-btn
                    v-for="button in timePicker.timeslots.evening.buttons"
                    :key="button.value"
                    color="primary"
                    :outlined="!button.active"
                    :disabled="button.disabled"
                    class="ma-1"
                    @click="timeClicked(button.value)"
                  >
                    {{ button.label }}
                  </v-btn>
                </div>
              </div>
              <v-overlay absolute :value="timePicker.loading" opacity="0">
                <v-progress-circular
                  indeterminate
                  size="64"
                  color="red"
                ></v-progress-circular>
              </v-overlay>
            </div>
          </v-card>
          <v-btn color="primary" @click="validateStepper(4)">Continue</v-btn>
          <v-btn text @click="e6 = 3">Previous</v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 5" step="5"
          >Select location
          <small v-if="selectedData.location.location_name" class="d-inline">{{
            selectedData.location.location_name
          }}</small>
        </v-stepper-step>
        <v-stepper-content step="5">
          <v-card color="" class="mb-12">
            <v-alert
              v-if="!selectedData.appointment.need_location"
              type="success"
            >
              No location is required for this appointment. Click continue.
            </v-alert>
            <v-alert
              v-if="
                !selectedData.appointment.allow_location &&
                  selectedData.appointment.auto_location
              "
              type="success"
            >
              Location selection is disabled. However we will select the next
              available location for you automatically. Click continue.
            </v-alert>
            <v-list v-else>
              <v-list-item-group v-model="selected.location" color="primary">
                <template v-for="(location, index) in locationsList">
                  <v-list-item
                    :key="location.location_id"
                    :disabled="!location.free"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title class="d-sm-flex">
                          <span class="font-weight-bold my-title">{{
                            location.location_name
                          }}</span>
                          <v-spacer></v-spacer>
                          <div>
                            <v-chip v-if="location.free" color="info" dark>
                              Available
                              <v-icon right>mdi-check-circle-outline</v-icon>
                            </v-chip>
                            <v-chip v-else color="error" dark>
                              Not Available
                              <v-icon right>mdi-cancel</v-icon>
                            </v-chip>
                          </div>
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <div v-if="location.free">
                          <v-icon v-if="!active" color="grey lighten-1">
                            mdi-checkbox-blank-outline
                          </v-icon>

                          <v-icon v-else color="green">
                            mdi-checkbox-marked
                          </v-icon>
                        </div>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                  <v-divider :key="index"></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card>
          <v-btn color="primary" @click="validateStepper(5)">Continue</v-btn>
          <v-btn text @click="e6 = 4">Previous</v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 6" step="6">Summary</v-stepper-step>
        <v-stepper-content step="6">
          <v-card color="grey lighten-1" class="mb-12">
            <v-simple-table>
              <template>
                <tbody>
                  <tr>
                    <th>Appointment</th>
                    <td>{{ selectedData.appointment.appointment_title }}</td>
                  </tr>
                  <tr></tr>
                  <tr>
                    <th>Duration</th>
                    <td>{{ selectedData.appointment.duration }} mins</td>
                  </tr>
                  <tr v-if="selectedData.appointment.need_passes">
                    <th>No of Pass Required</th>
                    <td>{{ selectedData.appointment.passes }}</td>
                  </tr>
                  <tr v-if="selectedData.appointment.need_passes">
                    <th>Pass Used</th>
                    <td>{{ selectedData.pass.service_name }}</td>
                  </tr>
                  <tr v-if="selectedData.appointment.need_passes">
                    <th>Pass Balance</th>
                    <td>
                      {{
                        selectedData.pass.passes_counter -
                          selectedData.appointment.passes
                      }}
                    </td>
                  </tr>
                  <tr v-if="selectedData.appointment.need_stakeholder">
                    <th>Employee In Charge</th>
                    <td>
                      {{
                        selectedData.stakeholder.firstname +
                          " " +
                          selectedData.stakeholder.lastname
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>Appointment Date & Time</th>
                    <td>
                      {{ toDayDate(selectedData.datetime.date) }}<br />{{
                        toTimeRange(
                          selectedData.datetime.date +
                            " " +
                            selectedData.datetime.timefrom,
                          selectedData.datetime.date +
                            " " +
                            selectedData.datetime.timeto
                        )
                      }}
                    </td>
                  </tr>
                  <tr
                    v-if="
                      selectedData.appointment.need_location &&
                        selectedData.appointment.allow_location
                    "
                  >
                    <th>Location</th>
                    <td>{{ selectedData.location.location_name }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
          <div class="d-flex flex-wrap">
            <v-btn color="success" @click="bookAppointment"
              >Ready to Book</v-btn
            >
            <v-btn text @click="e6 = 5">Previous</v-btn>
            <v-btn color="warning" class="ml-auto" @click="resetStepper"
              >Reset</v-btn
            >
          </div>
        </v-stepper-content>
      </v-stepper>
    </div>
    <!-- </v-container>
    </v-main> -->
  </div>
</template>
<script>
// import Navigation from "@/components/Navigation";
import dateTimeMixin from "@/mixins/dateTimeMixin";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  data() {
    return {
      showSignIn: false,
      expand: false,
      selected: {
        appointment: null,
        pass: null,
        stakeholder: null,
        location: null,
      },
      e6: 1,
      loaded: false,
      selectedData: {
        appointment: {},
        pass: {},
        stakeholder: {},
        datetime: {
          date: "",
          timefrom: "",
          timeto: "",
        },
        location: {},
      },
      //appointmentsList: [],
      passesList: null,
      stakeholdersList: null,
      locationsList: null,
      requestor: {
        fullname: "",
      },
      datePicker: {
        value: new Date().toISOString().substr(0, 10),
        firstDayOfWeek: 1,
        min: moment().format("YYYY-MM-DD"),
        max: moment()
          .add(2, "M")
          .format("YYYY-MM-DD"),
      },
      timePicker: {
        timeslots: {
          morning: {
            ts: "06:00:00",
            te: "12:00:00",
            buttons: [],
          },
          afternoon: {
            ts: "12:00:00",
            te: "18:00:00",
            buttons: [],
          },
          evening: {
            ts: "18:00:00",
            te: "23:59:59",
            buttons: [],
          },
        },
        loading: false,
      },
    };
  },
  props: {
    cid: {
      required: true,
    },
  },
  async mounted() {
    if(this.customer.id){
      if(this.getRequestor()){
        if (!this.appointments || !this.appointments.length) {
          await this.fetchAppointmentsList();
        }
      }
    } else {
      await this.$store.dispatch("fetchCustomer");
    }
    // if (!this.customer.id) {
    //   await this.$store.dispatch("fetchCustomer");
    // }
    // if (!this.appointments || !this.appointments.length) {
    //   await this.fetchAppointmentsList();
    // }
    this.loaded = true;
  },
  methods: {
    async fetchAppointmentsList() {
      return await this.$store.dispatch({
        type: "fetchAppointmentsList",
        cid: this.cid,
        dcid: this.isDependant ? this.cid : null,
      });
    },
    async validateStepper(step) {
      switch (step) {
        case 1: // select an appointment
          this.selectedData.appointment = this.appointments[
            this.selected.appointment
          ];
          // set timeslots
          if (this.selectedData.appointment.timeslots_duration > 0) {
            //base.setTimeSlots(this.selectedData.appointment.timeslots_duration);
            for (let sessionStr in this.timePicker.timeslots) {
              //this.timePicker.timeslots[session];
              let done = false,
                session = this.timePicker.timeslots[sessionStr],
                btns = session.buttons,
                startTime = moment(session.ts, "H:mm:ss"),
                endTime = moment(session.te, "H:mm:ss");
              btns = [];
              while (!done) {
                btns.push({
                  label: startTime.format("h:mmA"),
                  value: startTime.format("HH:mm:ss"),
                  disabled: false,
                  active: false,
                });
                startTime.add(
                  this.selectedData.appointment.timeslots_duration,
                  "minutes"
                ); //startTime =
                if (!startTime.isBefore(endTime)) done = true;
              }
              session.buttons = btns;
            }
          }
          // set datePicker min
          this.datePicker.min = moment()
            .add(this.selectedData.appointment.min_day, "days")
            .format("YYYY-MM-DD");
          if (this.selectedData.appointment.need_passes) {
            if (this.passesList == null) {
              let data = await this.$store.dispatch({
                type: "fetchAppointmentPasses",
                dcid: this.isDependant ? this.cid : null,
              });
              this.passesList = data.passes;
            }
            // check passes if got restriction
            if (this.selectedData.appointment.restrict_services.length) {
              // got restriction
              this.passesList.map(
                (p) =>
                  (p.allowed =
                    this.selectedData.appointment.restrict_services.indexOf(
                      p.service_id
                    ) !== -1)
              );
            } else {
              this.passesList.map((p) => (p.allowed = true));
            }
            this.e6 = 2;
          } else {
            this.selectedData.pass.service_name = "No pass require";
            this.validateStepper(2);
          }
          break;
        case 2: // passes
          if (this.selectedData.appointment.need_passes) {
            if (typeof this.selected.pass === "number") {
              if (
                this.passesList[this.selected.pass].passes_counter <
                this.selectedData.appointment.passes
              ) {
                Swal.fire({
                  icon: "error",
                  title: `Insufficient pass. Selected appointment require ${this.selectedData.appointment.passes} pass(es).`,
                });
                return;
              }
              this.selectedData.pass = this.passesList[this.selected.pass];
            } else {
              Swal.fire({
                icon: "error",
                title:
                  "Selected appointment require pass. Please select a pass to continue.",
              });
              return;
            }
          }
          if (this.selectedData.appointment.need_stakeholder) {
            if (this.selectedData.appointment.private_stakeholder) {
              if (this.selectedData.pass.stakeholder_id) {
                // got specially apointed trainer
                this.stakeholdersList = [this.selectedData.pass];
                this.selected.stakeholder = 0;
              }
            } else {
              try {
                let data = await this.$store.dispatch({
                  type: "fetchAppointmentStakeholders",
                  appid: this.selectedData.appointment.appointment_id,
                  dcid: this.isDependant ? this.cid : null,
                });
                this.stakeholdersList = data.stakeholders;
              } catch (err) {
                console.log("err", err);
              }
            }
          } else {
            this.stakeholdersList = {};
            this.selectedData.stakeholder.firstname = "No stakeholder require";
            this.validateStepper(3);
            return;
          }
          this.e6 = 3;
          break;
        case 3: // stakeholder
          if (this.selectedData.appointment.need_stakeholder) {
            if (typeof this.selected.stakeholder === "number") {
              this.selectedData.stakeholder = this.stakeholdersList[
                this.selected.stakeholder
              ];
            } else {
              Swal.fire({
                icon: "error",
                title:
                  "Selected appointment require stakeholder. Please select a stakeholder to continue.",
              });
              return;
            }
          }
          this.datePicker.value = this.datePicker.min;
          this.datePickerChanged(this.datePicker.value);
          this.e6 = 4;
          break;
        case 4: // datetime
          if (this.selectedData.datetime.date == "") {
            Swal.fire({
              icon: "error",
              title: "Invalid date and time selection. Please try again.",
            });
            return;
          }
          // validate location
          if (
            this.selectedData.appointment.need_location &&
            this.selectedData.appointment.allow_location
          ) {
            let data = await this.$store.dispatch({
              type: "fetchAppointmentLocations",
              appid: this.selectedData.appointment.appointment_id,
              date: this.selectedData.datetime.date,
              timefrom: this.selectedData.datetime.timefrom,
              timeto: this.selectedData.datetime.timeto,
            });
            this.locationsList = data.locations;
          } else {
            this.e6 = 6;
            return;
          }
          this.e6 = 5;
          break;
        case 5: // location
          if (
            this.selectedData.appointment.need_location &&
            this.selectedData.appointment.allow_location
          ) {
            if (typeof this.selected.location === "number") {
              this.selectedData.location = this.locationsList[
                this.selected.location
              ];
            } else {
              Swal.fire({
                icon: "error",
                title:
                  "Selected appointment require location. Please select a location to continue.",
              });
              return;
            }
          }
          this.e6 = 6;
      }
    },
    getRequestor() {
      let found = false;
      if (this.customer.id && this.cid !== this.requestor.id) {
        if (this.cid == this.customer.id) {
          this.requestor = this.customer;
          found = true;
        } else if(this.customer.dependants) {
          this.customer.dependants.forEach((dependant) => {
            if (this.cid == dependant.id) {
              this.requestor = dependant;
              found = true;
            }
          });
        }
      }
      if(found) {
        return this.requestor;
      } else {
        this.$router.replace({ 
          name: "MyAppointment",
          params: {
            cid: this.customer.id
          }
        })
        return false
      }
    },
    resetStepper() {
      this.passesList = null;
      this.stakeholdersList = null;
      this.locationsList = null;
      this.selected = {
        appointment: null,
        pass: null,
        stakeholder: null,
        location: null,
      };
      this.selectedData = {
        appointment: {},
        pass: {},
        stakeholder: {},
        datetime: {
          date: "",
          timefrom: "",
          timeto: "",
        },
        location: {},
      };
      this.e6 = 1;
    },
    async datePickerChanged(val) {
      this.timePicker.loading = true;
      let data = await this.$store.dispatch({
        type: "fetchAppointmentStakeholderSchedules",
        shid: this.selectedData.stakeholder.stakeholder_id,
        date: val,
        dcid: this.isDependant ? this.cid : null,
      });
      // data.workinghours
      // data.schedules
      if (this.isToday(val))
        data.schedules.push({
          // to only allow event to be selected after 2 hours if allowed selection is start today
          timefrom: moment().format("YYYY-MM-DD") + " 00:00:00",
          timeto: moment()
            .add("2", "hours")
            .startOf("hour")
            .format("YYYY-MM-DD H:mm:ss"),
        });
      for (let sessionStr in this.timePicker.timeslots) {
        //let session = this.timePicker.timeslots[sessionStr]
        //let timeslots = this.timePicker.timeslots
        this.timePicker.timeslots[sessionStr].buttons.map((b) => {
          b.disabled = true;
          b.active = false;
          let t = moment(val + " " + b.value).add(1, "minute"),
            tv = false; // time valid
          if (data.workinghours.length > 0) {
            data.workinghours.forEach((wh) => {
              let st = moment(val + " " + wh.timefrom),
                et = moment(val + " " + wh.timeto);
              if (t.isBetween(st, et)) {
                tv = true;
                // break
              }
            });
          }
          if (tv) {
            let c = false; // crash
            data.schedules.forEach((s) => {
              let stf = moment(s.timefrom), // schedule time from
                stt = moment(s.timeto); // schedule time to
              if (t.isBetween(stf, stt)) {
                c = true;
                // break - if use for
              }
            });
            if (!c) b.disabled = false;
          }
          return b;
        });
        //this.timePicker.timeslots[sessionStr].buttons = btns
        //this.$set(this.timePicker, 'timeslots', timeslots)
      }
      this.timePicker.loading = false;
    },
    timeClicked(value) {
      let d = this.selectedData.appointment.duration,
        tsd = this.selectedData.appointment.timeslots_duration,
        l = Math.ceil(d / tsd),
        t = moment(value, "HH:mm:ss"),
        n = 0,
        error = false;
      for (let sessionStr in this.timePicker.timeslots) {
        let session = this.timePicker.timeslots[sessionStr];
        session.buttons.forEach((b) => {
          if (b.value == value || (n > 0 && n < l)) {
            if (!error && b.disabled) {
              Swal.fire({
                icon: "error",
                text:
                  "Error selecting time slot. Time " +
                  moment(b.value, "H:mm:ss").format("h:mma") +
                  " is not available.",
              });
              error = true;
            } else {
              b.active = true;
              n += 1;
            }
          } else b.active = false;
        });
      }
      if (!error && n != l) {
        Swal.fire({
          icon: "error",
          text: "Error selecting time slot. Invalid time slot selection.",
        });
        error = true;
      }
      if (error) {
        for (let sessionStr in this.timePicker.timeslots) {
          this.timePicker.timeslots[sessionStr].buttons.map(
            (b) => (b.active = false)
          );
        }
        this.selectedData.datetime.date = "";
        this.selectedData.datetime.timefrom = "";
        this.selectedData.datetime.timeto = "";
      } else {
        this.selectedData.datetime.date = this.datePicker.value;
        this.selectedData.datetime.timefrom = t.format("HH:mm:ss");
        this.selectedData.datetime.timeto = moment(t)
          .add(d, "minutes")
          .format("HH:mm:ss");
      }
    },
    bookAppointment() {
      let data = {
        passes: this.selectedData.appointment.passes,
        appointment_id: this.selectedData.appointment.appointment_id,
        appointment_title: this.selectedData.appointment.appointment_title,
        customer_id: this.cid,
        service_record_id: this.selectedData.pass.service_record_id,
        stakeholder_id: this.selectedData.stakeholder.stakeholder_id,
        relationship: this.selectedData.stakeholder.relationship,
        date: this.selectedData.datetime.date,
        timefrom: this.selectedData.datetime.timefrom,
        timeto: this.selectedData.datetime.timeto,
        location_id: this.selectedData.location.location_id,
      };
      this.$store
        .dispatch({
          type: "createAppointment",
          dcid: this.isDependant ? this.cid : null,
          data: data,
        })
        .then((r) => {
          if (r.error == false) {
            this.$store.dispatch("fetchEvents");
            if (this.selectedData.appointment.need_passes)
              this.$store.dispatch("fetchCustomer"); // to update available active passes
            Swal.fire({
              icon: "success",
              title: "Appointment booking submitted successfully",
            }).then(() => {
              this.$router.push({
                name: "HomeMain",
              });
              this.resetStepper();
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "There is an error booking the appointment.",
              text: "Please try again. If the problem persist, kindly contact FeePlus."
            })
          }
        });
    },
  },
  computed: {
    business() {
      return this.$store.state.business;
    },
    customer() {
      return this.$store.state.customer;
    },
    appointments() {
      return this.$store.state.appointments_list[this.cid];
    },
    isDependant() {
      return this.$store.getters.isDependant(this.cid);
    },
    userType() {
      return this.$store.getters.userType();
    },
  },
  watch: {
    customer(n, o) {
      if (n.id !== o.id) {
        if(this.getRequestor()) this.fetchAppointmentsList();
      }
    },
  },
  components: {
    SignInDialog: () =>
      import(
        /* webpackChunkName: "SignInDialog" */ "@/components/SignInDialog"
      ),
  },
  mixins: [dateTimeMixin],
};
</script>
<style scoped>
.my-title {
  white-space: pre-wrap;
}
.clickable:hover {
  color: #2196f3;
  cursor: pointer;
}
</style>
